<!-- 
引入组件：import LimitedTimeCommodity from '@/components/LimitedTimeActivities/LimitedTimeCommodity.vue'
注册组件：
components: {
	LimitedTimeCommodity
},

组件使用：<LimitedTimeCommodity :Data="goodsData" @toDetail="toDetail"></LimitedTimeCommodity>
goodsData：商品数据
@toDetail：跳转详情事件
 -->
<template>
	<div class="flex-row-wrap">
		<div class="flex-column bg-fff Limited-time-activities-box margin-t-20" v-for="(item,index) of Data" :class="(index+1)%4 != 0?'margin-r-15':''"
		 :key="index">
			<div class="img-box flex-row-center-center" @click="toDetail(item)">
				<img :src="item.goods.fPicturePath" alt="" class="img-style">
			</div>
			<div class="flex-column padding-lr-10 margin-t-10">
				<div class="commodity-title-box font-size13 font-color-000 twoline-show" @click="toDetail(item)">
					{{item.goods.fGoodsFullName}}
				</div>
				<div class="margin-t-4 flex-row-align-center" @click="toDetail(item)">
					<div class="tag-bg margin-r-6 font-size12 font-color-fff flex-row-center-center" v-if="item.goods.fShopTypeName == '自营'">自营</div>
					<!-- <div class="tag-border font-size12 font-color-FF0000 border-FF0000">3倍积分</div> -->
				</div>
				<div class="shop-box flex-row-align-center font-size12" @click="toshop(item)">
					<div class="font-color-ccc margin-r-6 hide-text" >{{item.goods.fShopName}}</div>
					<div class="font-color-1e1e1e margin-r-2" style="width:30px;">进店</div>
					<!-- <div class="iconfont icon-youkuohao font-size12"></div> -->
					<i class="el-icon-arrow-right font-size12"></i>
				</div>
			</div>
			<div class="commodity-bottom-box padding-lr-10 flex-row-space-between-center" @click="toDetail(item)">
				<div class="flex-colum">
					<div class="font-size16 font-color-fff">
						<!--  -->
						<span class="font-weight700">￥{{item.fMinGoodsPrice}}元/吨</span>
						<!-- <span class="font-size12 margin-l-3">元/吨</span> -->
					</div>
				<!-- 	<div class="flex-row-align-center">
						<span class="font-size12 font-color-fff">已售{{item.progress}}%</span>
						<div class="progress margin-l-3">
							<el-progress :percentage="item.progress" :color="'#FFCC33'" :show-text="false"></el-progress>
						</div>
					</div> -->
				</div>
				<div class="rush-to-purchase-button flex-row-center-center font-size14">去抢购</div>
			</div>
		</div>
	</div>
</template>

<script>
	import countDowm from '../countDown/countDown.vue'
	export default {
		components: {
			countDowm
		},
		props: {
			Data: {
				type: Array,
				default: []
			}
		},
		data() {
			return {}
		},
		created(){
			console.log(this.Data)
		},
		methods: {
			//跳转商品详情事件传出
			toDetail(obj) {
				console.log(obj);
				// console.log('商品',obj);
				this.$emit('toDetail', JSON.stringify(obj))
			},
			//跳转商品所在店铺事件传出
			toshop(obj) {
				// console.log('店铺',obj);
				this.$emit('toshop', JSON.stringify(obj))
			}
		}
	}
</script>

<style lang="scss" scoped>
	.Limited-time-activities-box {
		width: 258px;
		height: 400px;
		border: 2px solid #f2f2f2;
	}

	.Limited-time-activities-box:hover {
		cursor: pointer;
		border: 2px solid #e01222;
	}

	.img-box {
		margin: 0 auto;
		width: 100%;
		height: 284px;
	}

	.icon-jinbi1 {
		color: #ffcc00;
		margin-right: 10px;
	}
	.img-style {
		width: 100%;
		height: 258px;
	}

	.commodity-title-box {
		height: 40px;
		line-height: 20px;
	}

	.tag-bg {
		padding: 0 4px;
		border-radius: 2px;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
	}

	.tag-border {
		padding: 0 4px;
		border-radius: 2px;
	}

	.shop-box {
		height: 24px;
	}

	.commodity-bottom-box {
		height: 55px;
		background-image: linear-gradient(to right top, #E93200, #F23026);
	}

	.rush-to-purchase-button {
		width: 64px;
		height: 34px;
		background-color: #fded74;
		border-radius: 2px;
		color: #e93200;
	}
	
	.progress{
		width: 70px;
	}
	
</style>
